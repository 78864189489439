import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43583692"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "outer" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", {
        id: "content",
        innerHTML: _ctx.content
      }, null, 8, _hoisted_3)
    ])
  ]))
}